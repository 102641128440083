import React, { useState } from "react";
import qs from "query-string";
import { Form, Card, Input, Button, message } from "antd";
import { UserOutlined, SafetyOutlined, SendOutlined } from '@ant-design/icons';
import { login } from "./api";
const strToTypeArray = (str) => {
  const passwordEncoder = new TextEncoder();
  const passwordUnit8Array = passwordEncoder.encode(str);
  return passwordUnit8Array;
}

const arrayBufferToStr = (buffer) => {
  const hashArray = Array.from(new Uint8Array(buffer));
  return hashArray.map(
    b => b.toString(16).padStart(2, '0')
  ).join('');
}

const App: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const onFinish = async (value) => {
    setLoading(true);
    const { email, password } = value;
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', strToTypeArray(password));
    const hashHex = arrayBufferToStr(hashBuffer);
    const loginInfo = {
      email,
      password: hashHex
    }
    try {
      await login(loginInfo);
      //重定向转回对应地址
      const redirectUrl = qs.parse(location.search)?.redirect as string;
      if (redirectUrl) {
        setTimeout(() => location.replace(redirectUrl), 1000);
      }
      message.success("登陆成功");
    } catch (e) {
      const { status } = e.response;
      switch (status) {
        case 401: 
          message.error('用户名或密码错误');
          break;
        default:
          message.error('服务异常')
      }
    } finally {
      setLoading(false);
    }
  }
  return (
    <div className="login">
      <Card title="统一登录" 
      bordered={false} 
      className="login-card"
      >
      <Form
    name="basic"
    size="large"
    labelCol={{ span: 4 }}
    initialValues={{ remember: true }}
    onFinish={onFinish}
    autoComplete="off"
  >
    <Form.Item
      label="邮箱"
      name="email"
      rules={[{ required: true, message: '请输入邮箱' }]}
    >
      <Input placeholder="请输入邮箱" prefix={<UserOutlined />} />
    </Form.Item>

    <Form.Item
      label="密码"
      name="password"
      rules={[{ required: true, message: '请输入密码' }]}
    >
      <Input.Password placeholder="请输入密码" prefix={<SafetyOutlined />}/>
    </Form.Item>
    <div className="form-buttons">
      <Button htmlType="submit" type="primary" icon={<SendOutlined />}>
        登录
      </Button>
    </div>
    </Form>
      </Card>
    </div>
  )
}

export default App;